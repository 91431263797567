/* .ant-advanced-search-form {
    padding: 10px;
}
  
.ant-advanced-search-form .ant-form-item {
    display: flex;
} */

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 0px !important;
}
.footer-advanced-form {
  margin: 5px;
  padding: 0px;
}

