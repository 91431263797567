
@import "./scroll-bar.css";
@import "./vars.css";
.MuiAppBar-colorSecondary {
    color: #fff !important;
    background-color: var(--primary-color) !important;
}

.MuiDrawer-root.MuiDrawer-docked.sidebar {
    background: #3E4545 !important;
    min-height: 100vh !important;
}

.MuiDrawer-root svg {
    color: #9c9c9c !important;
}

p.MuiTypography-root.MuiListItemText-secondary.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-displayBlock {
    color: #9c9c9c !important;
}
.RaMenuItemLink-root-679 {
    color: rgb(255 255 255 / 54%)!important;
}

.MuiListItem-root {
    color: #9c9c9c !important;
}
span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body2.MuiTypography-displayBlock {
    color: #9c9c9c !important;
}

a.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.menuItems.MuiMenuItem-gutters.MuiListItem-dense.MuiMenuItem-dense.MuiListItem-gutters.MuiListItem-button {
    color: #9c9c9c !important;
}
.RaListToolbar-actions-212,
.MuiToolbar-regular
{
    min-height: 0px!important;
    padding-top: 0px!important;
}

h1, h2, h3, h4, h5, h6 {

color: #3E4545 !important;
}
.dinamic-drawer .MuiDrawer-paperAnchorRight{
    max-width: 500px!important;
}
/* Edit Table */
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

.editable-row .ant-form-explain {
    position: absolute;
    font-size: 12px;
    margin-top: -4px;
  }
  .ant-select-selection {
    height: auto!important;
}
.global-search{
    width: 100%!important;
    display: block;
}
.section-options .ant-form-horizontal{
    justify-content: start!important;
}

.section-options .ant-form-horizontal .login-form-button{
    margin: -34px 20px!important;
}
.RaMenuItemLink-root-162 {
    color: rgba(255, 255, 255, 0.54)!important;
}
.RaMenuItemLink-root-136 {
    color: rgba(255, 255, 255, 0.54)!important;
}
.ant-upload.ant-upload-drag{
    max-width: 80%!important;
    margin: 10px auto;
}
.MuiTableCell-root .MuiButton-label span{
    display: none!important;
}